import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import clsx from "clsx"

import Navbar from "../components/Navbar"
import SEO from "../components/SEO"
import useTranslation from "../translations/translate"

const postings = [
  {
    active: true,
    title: "Maschinenmonteur/-mechaniker",
    description: (
      <>
        <p className="fw-bold">Aufgaben des Bewerbers</p>
        <ul>
          <li>Montage von Baugruppen und Maschinen</li>
          <li>Montage von Maschinensteuerungen</li>
          <li>Arbeiten nach Plänen und Zeichnungen</li>
          <li>Erstellung von Stücklisten</li>
        </ul>

        <p className="fw-bold">Gewünschte Qualifikationen</p>
        <ul>
          <li>
            Erfahrung als Mechaniker, Mechatroniker, Elektriker,
            Industriemonteur, bzw. im Maschinenbau sind von Vorteil
          </li>
          <li>Handwerkliches/technisches Geschick</li>
          <li>Selbstständige, zuverlässige und sorgfältige Arbeitsweise</li>
        </ul>

        <p className="fw-bold">Was bieten wir dem Bewerber?</p>
        <p>
          EVOBEND bietet Ihnen bei entsprechender Leistung ein sehr gutes
          Gehalt. Es herrscht ein Arbeitgeber-Dienstnehmer-Verhältnis auf
          Augenhöhe.
        </p>
        <ul>
          <li>
            Angenehmes, kollegiales Arbeitsklima, unbefristeter Arbeitsvertrag
          </li>
          <li>
            Mitarbeiter zählt als Mensch und nicht als ein Produktionsfaktor
          </li>
          <li>Sichere Arbeitsplätze</li>
          <li>Innovatives Arbeitsumfeld</li>
          <li>Kostenlos Kaffee und Mineralwasser für alle Angestellten</li>
          <li>Urlaubsgeld</li>
          <li>Weihnachtsgeld</li>
          <li>Prämien und Ausgleichszahlungen (Inflationsausgleich) möglich</li>
          <li>Prämie „Mitarbeiter werben Mitarbeiter“</li>
          <li>Arbeitskleidung wird gestellt</li>
          <li>Eigenes Werkzeug wird gestellt</li>
          <li>Weihnachtsfeier</li>
          <li>Sommerfeier</li>
          <li>Geburtstagsgutscheine</li>
          <li className="fw-bold">und mehr!</li>
        </ul>
      </>
    ),
  },
  {
    active: true,
    title: "Techniker (im Außendienst)",
    description: (
      <>
        <p className="fw-bold">Aufgaben des Bewerbers</p>
        <ul>
          <li>
            Aufstellung und Inbetriebnahme von Maschinen bei Endkunden - global
            (meist Europa)
          </li>
          <li>Schulung von Personal bei Endkunden - global (meist Europa)</li>
          <li>
            Aufstellung und Inbetriebnahme von Maschinen bei Messen und
            Ausstellungen - global (meist Europa)
          </li>
          <li>
            Anfallende Reparaturen und Servicetätigkeiten - global (meist
            Europa)
          </li>
          <li>Montage von Baugruppen und Maschinen</li>
          <li>Montage von Maschinensteuerungen</li>
          <li>Arbeiten nach Plänen und Zeichnungen</li>
          <li>Erstellung von Stücklisten</li>
        </ul>

        <p className="fw-bold">Gewünschte Qualifikationen</p>
        <ul>
          <li>
            Erfahrung als Servicetechniker, Baumaschinen-Mechaniker, Mechaniker,
            Mechatroniker, Elektriker, Industriemonteur, bzw. im Maschinenbau
            sind von Vorteil
          </li>
          <li>Handwerkliches/technisches Geschick</li>
          <li>Selbstständige, zuverlässige und sorgfältige Arbeitsweise</li>
          <li>Reisebereitschaft</li>
          <li>Gute Englischkenntnisse</li>
          <li>Führerschein Klasse B</li>
        </ul>

        <p className="fw-bold">Was bieten wir dem Bewerber?</p>
        <p>
          EVOBEND bietet Ihnen bei entsprechender Leistung ein sehr gutes
          Gehalt. Es herrscht ein Arbeitgeber-Dienstnehmer-Verhältnis auf
          Augenhöhe.
        </p>
        <ul>
          <li>
            Angenehmes, kollegiales Arbeitsklima, unbefristeter Arbeitsvertrag
          </li>
          <li>
            Mitarbeiter zählt als Mensch und nicht als ein Produktionsfaktor
          </li>
          <li>Sichere Arbeitsplätze</li>
          <li>Innovatives Arbeitsumfeld</li>
          <li>Kostenlos Kaffee und Mineralwasser für alle Angestellten</li>
          <li>Urlaubsgeld</li>
          <li>Weihnachtsgeld</li>
          <li>Prämien und Ausgleichszahlungen (Inflationsausgleich) möglich</li>
          <li>Prämie „Mitarbeiter werben Mitarbeiter“</li>
          <li>Arbeitskleidung wird gestellt</li>
          <li>Eigenes Werkzeug wird gestellt</li>
          <li>Weihnachtsfeier</li>
          <li>Sommerfeier</li>
          <li>Geburtstagsgutscheine</li>
          <li className="fw-bold">und mehr!</li>
        </ul>
      </>
    ),
  },
  {
    active: false,
    title: "Bürokraft",
    description: (
      <>
        <p className="fw-bold">Aufgaben des Bewerbers</p>
        <ul>
          <li>Unterstützung der Geschäftsleitung</li>
          <li>Unterstützung der Einkaufsabteilung</li>
          <li>Unterstützung der Buchhaltungsabteilung</li>
          <li>
            Erledigung diverser Arbeiten (Reisen planen, Feiern planen, Einkäufe
            tätigen, etc.)
          </li>
          <li>
            Erstellung von Stücklisten, Erstellung von Plänen, Erstellung von
            Anleitungen (keine Vorkenntnisse notwendig)
          </li>
        </ul>

        <p className="fw-bold">Gewünschte Qualifikationen</p>
        <ul>
          <li>
            Geübter Umgang mit Computern (Google Workspace/Microsoft Office)
          </li>
          <li>(Grundlegende) Englischkenntnisse</li>
          <li>Organisiertes, höfliches und geduldiges Auftreten</li>
          <li>Selbstständige, zuverlässige und sorgfältige Arbeitsweise</li>
        </ul>

        <p className="fw-bold">Was bieten wir dem Bewerber?</p>
        <p>
          EVOBEND bietet Ihnen bei entsprechender Leistung ein sehr gutes
          Gehalt. Es herrscht ein Arbeitgeber-Dienstnehmer-Verhältnis auf
          Augenhöhe.
        </p>
        <ul>
          <li>
            Angenehmes, kollegiales Arbeitsklima, unbefristeter Arbeitsvertrag
          </li>
          <li>
            Mitarbeiter zählt als Mensch und nicht als ein Produktionsfaktor
          </li>
          <li>Sichere Arbeitsplätze</li>
          <li>Innovatives Arbeitsumfeld</li>
          <li>Kostenlos Kaffee und Mineralwasser für alle Angestellten</li>
          <li>Urlaubsgeld</li>
          <li>Weihnachtsgeld</li>
          <li>Prämien und Ausgleichszahlungen (Inflationsausgleich) möglich</li>
          <li>Prämie „Mitarbeiter werben Mitarbeiter“</li>
          <li>Arbeitskleidung wird gestellt</li>
          <li>Weihnachtsfeier</li>
          <li>Sommerfeier</li>
          <li>Geburtstagsgutscheine</li>
          <li className="fw-bold">und mehr!</li>
        </ul>
      </>
    ),
  },
  {
    active: true,
    title: "Elektriker/Elektroniker",
    description: (
      <>
        <p className="fw-bold">Aufgaben des Bewerbers</p>
        <ul>
          <li>Montage von Maschinensteuerungen</li>
          <li>Montage von Baugruppen</li>
          <li>Erstinbetriebnahme von Maschinen</li>
          <li>Arbeiten nach Plänen und Zeichnungen</li>
          <li>Erstellung von Stücklisten</li>
        </ul>

        <p className="fw-bold">Gewünschte Qualifikationen</p>
        <ul>
          <li>
            Erfahrung als Elektriker, Elektroniker, (KFZ-) Mechatroniker,
            Industriemonteur, (KFZ-) Mechaniker bzw. im Maschinenbau sind von
            Vorteil
          </li>
          <li>Handwerkliches/technisches Geschick</li>
          <li>Organisiertes, höfliches und geduldiges Auftreten</li>
          <li>Selbstständige, zuverlässige und sorgfältige Arbeitsweise</li>
        </ul>

        <p className="fw-bold">Was bieten wir dem Bewerber?</p>
        <p>
          EVOBEND bietet Ihnen bei entsprechender Leistung ein sehr gutes
          Gehalt. Es herrscht ein Arbeitgeber-Dienstnehmer-Verhältnis auf
          Augenhöhe.
        </p>
        <ul>
          <li>
            Angenehmes, kollegiales Arbeitsklima, unbefristeter Arbeitsvertrag
          </li>
          <li>
            Mitarbeiter zählt als Mensch und nicht als ein Produktionsfaktor
          </li>
          <li>Sichere Arbeitsplätze</li>
          <li>Innovatives Arbeitsumfeld</li>
          <li>Kostenlos Kaffee und Mineralwasser für alle Angestellten</li>
          <li>Urlaubsgeld</li>
          <li>Weihnachtsgeld</li>
          <li>Prämien und Ausgleichszahlungen (Inflationsausgleich) möglich</li>
          <li>Prämie „Mitarbeiter werben Mitarbeiter“</li>
          <li>Arbeitskleidung wird gestellt</li>
          <li>Weihnachtsfeier</li>
          <li>Sommerfeier</li>
          <li>Geburtstagsgutscheine</li>
          <li className="fw-bold">und mehr!</li>
        </ul>
      </>
    ),
  },
  {
    active: true,
    title: "Einkäufer",
    description: (
      <>
        <p className="fw-bold">Aufgaben des Bewerbers</p>
        <ul>
          <li>Mitarbeit in der Einkaufsabteilung</li>
          <li>Einkauf der Bauteile für unsere Maschinen</li>
          <li>Verhandlungen mit Zulieferern führen</li>
          <li>
            Erstellung von Stücklisten, Erstellung von Plänen, Erstellung von
            Anleitungen (keine Vorkenntnisse notwendig)
          </li>
        </ul>

        <p className="fw-bold">Gewünschte Qualifikationen</p>
        <ul>
          <li>
            Geübter Umgang mit Computern (Google Workspace/Microsoft Office)
          </li>
          <li>(Grundlegende) Englischkenntnisse</li>
          <li>Organisiertes, höfliches und geduldiges Auftreten</li>
          <li>Selbstständige, zuverlässige und sorgfältige Arbeitsweise</li>
        </ul>

        <p className="fw-bold">Was bieten wir dem Bewerber?</p>
        <p>
          EVOBEND bietet Ihnen bei entsprechender Leistung ein sehr gutes
          Gehalt. Es herrscht ein Arbeitgeber-Dienstnehmer-Verhältnis auf
          Augenhöhe.
        </p>
        <ul>
          <li>
            Angenehmes, kollegiales Arbeitsklima, unbefristeter Arbeitsvertrag
          </li>
          <li>
            Mitarbeiter zählt als Mensch und nicht als ein Produktionsfaktor
          </li>
          <li>Sichere Arbeitsplätze</li>
          <li>Innovatives Arbeitsumfeld</li>
          <li>Kostenlos Kaffee und Mineralwasser für alle Angestellten</li>
          <li>Urlaubsgeld</li>
          <li>Weihnachtsgeld</li>
          <li>Prämien und Ausgleichszahlungen (Inflationsausgleich) möglich</li>
          <li>Prämie „Mitarbeiter werben Mitarbeiter“</li>
          <li>Arbeitskleidung wird gestellt</li>
          <li>Weihnachtsfeier</li>
          <li>Sommerfeier</li>
          <li>Geburtstagsgutscheine</li>
          <li className="fw-bold">und mehr!</li>
        </ul>
      </>
    ),
  },
]

function CareerPage() {
  const { getLocalizedPath } = useTranslation()

  const [open, setOpen] = useState()

  const onSubmit = e => {
    const cvFileSize =
      e.target.cvFileInput.files.length === 0
        ? 0
        : e.target.cvFileInput.files[0].size
    const motivationLetterFileSize =
      e.target.motivationLetterFileInput.files.length === 0
        ? 0
        : e.target.motivationLetterFileInput.files[0].size

    const totalSize = cvFileSize + motivationLetterFileSize

    if (totalSize > 8 * 1000000) {
      e.preventDefault()
      alert("Die Dateien dürfen nicht größer als 8MB sein!")
      return
    }
  }

  return (
    <>
      <SEO
        title="Karriere"
        description="EVOBEND ist immer auf der Suche nach neuen Mitgliedern für unser Team, die uns dabei helfen weiterhin erfolgreich zu sein."
      />

      <section className="position-relative vh-60 p-0 border-bottom">
        <Navbar
          color="dark"
          className="position-absolute top-0 left-0 w-100 z-100"
        />

        <StaticImage
          src="../components/assets/media/images/career.jpeg"
          layout="fullWidth"
          quality="90"
          breakpoints={[750, 1080, 1366, 1920, 2560]}
          className="h-100 object-position-center"
          alt="Career"
          transformOptions={{
            fit: "cover",
          }}
          imgStyle={{
            objectPosition: "50% 10%",
          }}
        />
      </section>

      <section className="container">
        <h1>Karriere bei EVOBEND</h1>
        <p className="lead">
          Sie interessieren sich für Maschinenbau, für Blechbearbeitung, wollten
          schon immer eine Arbeit ausführen, bei der man die Ergebnisse dessen,
          was man macht unmittelbar sehen kann? Oder Sie sind einfach auf der
          Suche nach etwas neuem?
        </p>
        <p className="lead">
          Wir bei EVOBEND sind immer auf der Suche nach neuen Mitgliedern im
          Team, die uns dabei helfen gesund zu wachsen und weiterhin erfolgreich
          zu sein.
        </p>
        <p className="lead">
          In unserer Firma gibt es eine Vielzahl an verschiedenen Bereichen, wie
          es bei einem Maschinenbauunternehmen üblich ist: Vertrieb,
          Rechnungswesen, Einkauf, Arbeitsvorbereitung, Lagerhaltung, Montage,
          Service und natürlich auch Marketing.
        </p>
        <p className="lead">
          Eine Unternehmung kann nur so gut sein wie die Menschen, die dahinter
          stehen und somit überhaupt erst eine Organisation, eine Firma bilden.
        </p>
        <p className="lead">
          Gerne möchten wir hier betonen, dass wir uns auch sehr über
          Initiativbewerbungen freuen und diese ausnahmslos auch alle
          beantworten. Bitte sehen Sie uns aber nach, wenn eine Antwort einige
          Tage dauern kann.
        </p>

        <h2 className="mt-6">Offene Stellen</h2>
        <div className={clsx("accordion mb-4", open !== undefined && "open")}>
          {postings
            .filter(p => p.active)
            .map(({ title, description }, idx) => (
              <div key={idx} className="accordion-item">
                <h3 className="accordion-header">
                  <button
                    className={clsx(
                      "accordion-button",
                      open !== idx && "collapsed"
                    )}
                    type="button"
                    aria-expanded="true"
                    onClick={() => setOpen(open === idx ? undefined : idx)}
                  >
                    {title} (m/w/d)
                  </button>
                </h3>

                <div
                  className={clsx(
                    "accordion-collapse",
                    "collapse",
                    open === idx && "show"
                  )}
                >
                  <div className="accordion-body">{description}</div>
                </div>
              </div>
            ))}
        </div>

        <h2 className="mt-6">Bewerben</h2>
        <div className="rounded border shadow card-body">
          <form
            name="Bewerbungen"
            method="post"
            action={getLocalizedPath("thanks")}
            encType="multipart/form-data"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={onSubmit}
          >
            <input type="hidden" name="form-name" value="Bewerbungen" />
            <div className="d-none">
              <label>
                Don't fill this out if you're human: <input name="bot-field" />
              </label>
            </div>
            <div className="mb-3">
              <label htmlFor="nameInput" className="form-label mb-0">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="nameInput"
                name="Name"
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="emailInput" className="form-label mb-0">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="emailInput"
                name="Email"
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="telephoneInput" className="form-label mb-0">
                Telefon
              </label>
              <input
                type="tel"
                className="form-control"
                id="telephoneInput"
                name="Phone"
                required
              />
            </div>

            <div className="row row-cols-1 row-cols-md-2">
              <div className="mb-3">
                <label htmlFor="positionSelect" className="form-label mb-0">
                  Stelle
                </label>
                <select
                  className="form-control form-select py-3"
                  id="positionSelect"
                  name="Position"
                  defaultValue="sonstige"
                  required
                >
                  {postings
                    .filter(posting => posting.active)
                    .map((posting, idx) => (
                      <option key={idx} value={posting.title}>
                        {posting.title} (m/w/d)
                      </option>
                    ))}
                  <option value="sonstige">Sonstige/Initiativbewerbung</option>
                </select>
              </div>

              <div className="mb-3">
                <label htmlFor="entryDateInput" className="form-label mb-0">
                  Frühestmögliches Eintrittsdatum
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="entryDateInput"
                  name="Entry date"
                  required
                />
              </div>
            </div>

            <div className="row row-cols-1 row-cols-md-2">
              <div className="mb-3">
                <label htmlFor="cvFileInput" className="form-label mb-0">
                  Lebenslauf (CV)
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="cvFileInput"
                  name="CV"
                  accept=".doc,.docx,.odt,.pages,.pdf"
                  required
                />
              </div>

              <div className="mb-3">
                <label
                  htmlFor="motivationLetterFileInput"
                  className="form-label mb-0"
                >
                  Motivationsschreiben
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="motivationLetterFileInput"
                  name="Motivation letter"
                  accept=".doc,.docx,.odt,.pages,.pdf"
                  required
                />
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="otherInput" className="form-label mb-0">
                Sonstiges (optional)
              </label>
              <textarea
                className="form-control"
                id="otherInput"
                name="Other"
                rows="3"
              />
            </div>

            <div className="mb-3 d-flex flex-row justify-content-start align-items-center">
              <input
                className="form-check-input m-0"
                type="checkbox"
                id="agreeCheck"
                required
              />
              <label className="form-check-label ms-1" htmlFor="agreeCheck">
                Hiermit bestätige ich, dass ich die{" "}
                <Link to={getLocalizedPath("privacy")}>
                  Datenschutzerklärung
                </Link>{" "}
                akzeptiere.
              </label>
            </div>

            <button type="submit" className="btn btn-primary mx-auto">
              Bewerbung absenden
            </button>
          </form>
        </div>
      </section>
    </>
  )
}

export default CareerPage
